import React from 'react'
import './HomepageMedia.module.scss'
import { MEDIA_WEB } from '../../../shared/constants';
import { MEDIA_MOBILE } from '../../../shared/constants';

const HomepageMedia = () => (
    <div className='media-wrapper'>
        <div className='media-container'>
            <h2 className='media-title'>Telah Diliput Oleh Berbagai Media Ternama</h2>
            <div className='media-content-web' key={`media-web`}>
                <img className='media-img-web'
                    src={MEDIA_WEB}
                    alt="" />
            </div>
            <div className='media-content-mobile' key={`media-mobile`}>
                <img className='media-img-mobile'
                    src={MEDIA_MOBILE}
                    alt="" />
            </div>
        </div>
    </div>
);

export default HomepageMedia;